import PackerLayout from "Packer/Layout"
import { Navigate } from "react-router-dom"

import Pages from "./Pages"

const AdminRoutes = [
  {
    path: "/",
    element: <PackerLayout />,
    children: [
      {
        path: "profile",
        element: <Pages.PackerProfile />,
      },
      {
        index: true,
        element: <Pages.PackerPacking />,
      },
      {
        permission: "order_list",
        path: "check-history",
        element: <Pages.PackerCheckHistory />,
      },
      {
        path: "hold",
        element: <Pages.PackerHold />,
      },
      {
        path: "box",
        element: <Pages.PackerPackageContainers />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/" />,
    children: [],
  },
]

if (process.env.REACT_APP_MODE === "development") {
  AdminRoutes.push()
}

export default AdminRoutes
