const { lazy } = require("react")

const Pages = {
  Signin: lazy(() => import("Packer/Pages/Auth/Signin")),
  RestorePassword: lazy(() => import("Packer/Pages/Auth/RestorePassword")),

  PackerCheckHistory: lazy(() => import("Packer/Pages/CheckHistory")),
  PackerPacking: lazy(() => import("Packer/Pages/Packing")),
  PackerPackageContainers: lazy(() => import("Packer/Pages/PackageContainers")),
  PackerHold: lazy(() => import("Packer/Pages/Hold")),
  PackerProfile: lazy(() => import("Packer/Pages/Profile")),
}

export default Pages
