import { Suspense, useEffect } from "react"
import { Outlet } from "react-router-dom"
import { Restore } from "Packer/components"
import { get } from "lodash"
import { useMutate } from "hooks"

import ScannedProductInfo from "./ScannedProductInfo"

import "./styles.scss"
import "./packer-modal.scss"

function PackerLayout() {
  const { mutate } = useMutate({
    url: "box-numbers",
    onSuccess: (res) => {
      if (res.data) {
        try {
          sessionStorage.setItem("containers", JSON.stringify(get(res, "data", [])))
        } catch (err) {
          //
        }
      }
    },
  })

  useEffect(() => {
    mutate({})
  }, [])

  return (
    <div className="packer packer-layout">
      {/* <Suspense>
        <PackerNavbar />
        <Filter />
      </Suspense> */}
      <div className="packer-wrapper">
        <Suspense>
          <Outlet />
        </Suspense>
      </div>
      <Suspense>
        <ScannedProductInfo />
      </Suspense>
      <Restore />
    </div>
  )
}

export default PackerLayout
